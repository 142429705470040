import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ProjectsPage = props => {
  return (
    <Layout section="projects">
      <SEO
        title="Projects"
        description="Curated selection of projects I’ve been working on in the last few
        years."
      />
      <h1>
        <strong>Projects</strong>
      </h1>

      <p>
        Curated selection of projects I’ve been working on in the last few
        years.
      </p>

      <article className="project">
        <h2>Beecool (2020)</h2>
        <p>
          Beecool sell resusable beeswax wraps all over Spain. All their processes are completely handcrafted,
          and they wanted a tailor made e-commerce.
        </p>
        <ul className="project-details">
          <li>
            <span className="term-title">Link</span>
            <span className="term-value">
              <a
                href="https://beecool.es/"
                target="_blank"
                rel="noopener noreferrer"
              >
                beecool.es →
              </a>
            </span>
          </li>
          <li>
            <span className="term-title">Client</span>
            <span className="term-value">Beecool</span>
          </li>
          <li>
            <span className="term-title">Technology</span>
            <span className="term-value"> 
              <p>Drupal 9 + GatsbyJS + ReactJS + Redux<br/>
              * Graphic design by <a href="https://www.tropicalestudio.com/" target="_blank" rel="noopener noreferrer">
                https://www.tropicalestudio.com/
              </a>
              </p>
            </span>
          </li>
          <li>
            <span className="term-title">Technical highlights</span>
            <span className="term-value">
              <ul>
                <li>Existing Drupal 8 site migration to Drupal 9</li>
                <li>Drupal backoffice to manage products, carts and orders</li>
                <li>Drupal API:</li>
                <ul>
                  <li>Gatsby builds from product data stored in Drupal</li>
                  <li>Endpoints to handle the cart, checkout and forms submissions</li>
                </ul>
                <li>Advanced customizer tool to build custom product packs</li>
                <li>Stripe integration: credit card payment on-site</li>
              </ul>
            </span>
          </li>
        </ul>
      </article>

      <article className="project">
        <h2>Eaglemoss (2019)</h2>
        <p>
          Eaglemoss is the leading partwork publisher with over 40 years of
          experience and a passion for creative and innovative collectible
          products.
        </p>
        <ul className="project-details">
          <li>
            <span className="term-title">Link</span>
            <span className="term-value">
              <a
                href="https://en-us.eaglemoss.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                en-us.eaglemoss.com →
              </a>
            </span>
          </li>
          <li>
            <span className="term-title">Client</span>
            <span className="term-value">Avatar New York (freelance work)</span>
          </li>
          <li>
            <span className="term-title">Technology</span>
            <span className="term-value">Drupal 8</span>
          </li>
          <li>
            <span className="term-title">Technical highlights</span>
            <span className="term-value">
              <ul>
                <li>Fully customized e-commerce site</li>
                <li>Multi-domain architecture with 10+ countries</li>
                <li>Integration with several country payment gateways</li>
                <li>GTM datalayer integration</li>
                <li>Highly customizable checkout</li>
                <li>Integration with their ERP</li>
                <li>Flexible page creation via paragraphs</li>
              </ul>
            </span>
          </li>
        </ul>
      </article>

      <article className="project">
        <h2>WPP breathing logo (2019)</h2>
        <p>
          WPP is the world’s largest advertising company that creates
          transformative ideas and outcomes for its clients through an
          integrated offer of communications, experience, commerce and
          technology.
        </p>
        <ul className="project-details">
          <li>
            <span className="term-title">Link</span>
            <span className="term-value">
              <a
                href="https://www.fontsmith.com/blog/2019/06/17/fontsmith-and-vbat-create-the-world-s-first-variable-font-logo"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.fontsmith.com/blog/... →
              </a>
            </span>
          </li>
          <li>
            <span className="term-title">Client</span>
            <span className="term-value">Fontsmith (freelance work)</span>
          </li>
          <li>
            <span className="term-title">Technology</span>
            <span className="term-value">Javascript</span>
          </li>
          <li>
            <span className="term-title">Technical highlights</span>
            <span className="term-value">
              <ul>
                <li>Variable Font OpenType controller</li>
                <li>Creation of a Javascript motion detection system</li>
                <li>
                  Variable font control: manual, motion based and time based
                </li>
              </ul>
            </span>
          </li>
        </ul>
      </article>

      <article className="project">
        <h2>DiMarzio (2018)</h2>
        <p>
          DiMarzio is a United States manufacturer best known for its
          direct-replacement guitar pickups and also produces miscellaneous
          guitar accessories, such as cables, straps and hardware.
        </p>
        <ul className="project-details">
          <li>
            <span className="term-title">Link</span>
            <span className="term-value">
              <a
                href="https://www.dimarzio.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.dimarzio.com →
              </a>
            </span>
          </li>
          <li>
            <span className="term-title">Client</span>
            <span className="term-value">Avatar New York (freelance work)</span>
          </li>
          <li>
            <span className="term-title">Technology</span>
            <span className="term-value">Drupal 8 + Drupal commerce</span>
          </li>
          <li>
            <span className="term-title">Technical highlights</span>
            <span className="term-value">
              <ul>
                <li>Pickup picker JS tool to find the right picker</li>
                <li>ZIP / Address dealer location search</li>
                <li>Youtube video integration</li>
                <li>Product migration from CSV</li>
              </ul>
            </span>
          </li>
        </ul>
      </article>

      <article className="project">
        <h2>Mohawk Paper (2017–2018)</h2>
        <p>
          Mohawk is a 4th generation United States company, family owned paper
          makers whose purpose is to make print more beautiful, effective and
          memorable.
        </p>
        <ul className="project-details">
          <li>
            <span className="term-title">Link</span>
            <span className="term-value">
              <a
                href="https://www.mohawkconnects.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.mohawkconnects.com →
              </a>
            </span>
          </li>
          <li>
            <span className="term-title">Client</span>
            <span className="term-value">Avatar New York (freelance work)</span>
          </li>
          <li>
            <span className="term-title">Technology</span>
            <span className="term-value">Drupal 8</span>
          </li>
          <li>
            <span className="term-title">Technical highlights</span>
            <span className="term-value">
              <ul>
                <li>Fully customized e-commerce site</li>
                <li>Facet search powered by SOLR</li>
                <li>
                  Daily product migration / synchronization via external API
                </li>
                <li>Integration with shipping providers</li>
                <li>ZIP / Radius search</li>
              </ul>
            </span>
          </li>
        </ul>
      </article>

      <article className="project">
        <h2>Soyvisual (2016)</h2>
        <p>
          Soyvisual is an Augmentative Communication System that uses
          illustrations and pictures to stimulate language and help people with
          communication needs.
        </p>
        <ul className="project-details">
          <li>
            <span className="term-title">Link</span>
            <span className="term-value">
              <a
                href="https://www.soyvisual.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.soyvisual.org →
              </a>
            </span>
          </li>
          <li>
            <span className="term-title">Client</span>
            <span className="term-value">
              Tropical Estudio / Orange (freelance work)
            </span>
          </li>
          <li>
            <span className="term-title">Technology</span>
            <span className="term-value">Drupal 7</span>
          </li>
          <li>
            <span className="term-title">Technical highlights</span>
            <span className="term-value">
              <ul>
                <li>Predictive search</li>
                <li>API Rest to serve external apps</li>
                <li>Downloader manager for picture selection</li>
              </ul>
            </span>
          </li>
        </ul>
      </article>

      <article className="project">
        <h2>University of Zaragoza (2014)</h2>
        <p>
          University of Zaragoza, founded in 1542, is one of the oldest
          universities in Spain. It has over 40,000 students in its 22 faculties
          and is the only public university in its region.
        </p>
        <ul className="project-details">
          <li>
            <span className="term-title">Link</span>
            <span className="term-value">
              <a
                href="https://unizar.es/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.unizar.es →
              </a>
            </span>
          </li>
          <li>
            <span className="term-title">Client</span>
            <span className="term-value">University of Zaragoza</span>
          </li>
          <li>
            <span className="term-title">Technology</span>
            <span className="term-value">Drupal 7</span>
          </li>
          <li>
            <span className="term-title">Technical highlights</span>
            <span className="term-value">
              <ul>
                <li>
                  Multi-site architecture, supporting 120+ sites with the same
                  codebase
                </li>
                <li>Installation profiles for the different areas</li>
                <li>News integration with the University press service</li>
              </ul>
            </span>
          </li>
        </ul>
      </article>
    </Layout>
  )
}

export default ProjectsPage
